import React from "react"
import { graphql, Link } from "gatsby"

export default function Home({ data }) {
  let showOnlyContent = process.env.SHOW_ONLY_CONTENT
  if (showOnlyContent === "true") {      
    if (typeof window !== 'undefined') {
      window.location = '/404/index.html';
    }
    return null;
  }

  let pages = data.allMarkdownRemark.edges.map(({ node }) => node)

  pages.sort((node1, node2) =>
    node1.frontmatter.path > node2.frontmatter.path ? 1 : -1
  )

  return (
    <div>
      <h1>Software Engineering 9-Month Program</h1>
      <h2>
        <Link to="/curriculum/index.html">Home</Link>
      </h2>
      <ul>
        {pages.map(node => (
          <li key={node.id}>
            <Link to={node.frontmatter.path}>{node.frontmatter.path}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
